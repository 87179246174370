<!--  -->
<template>
  <div class=''>
    <div style="font-size: 35px">开放预约时间</div>
    <el-divider></el-divider>
<!--    使用穿梭框来供医生进行时间选择。-->
<!--    穿梭框暂时采用星期几-上午/下午格式，采用星期出于方便复用的考虑，向后台发送数据时要想办法换算成日期（暂时没写）-->
<!--    时间数据在发送前/后也需要换算，将上午/下午拆分成几个诊疗时间段-->
<!--    最终用户发起预约时看到的可预约时间应该是一个“日期：时间1-时间2”的形式，存储时考虑存储为日期、开始时间、结束时间三个数据-->
<!--    考虑存储在医生信息页面（姓名，职称。。。那些），供用户预约页面读取-->
    <template>
      <el-transfer
          v-model="value"
          :data="tdata"
          :titles="['备选时间','已选时间']"
      >
      </el-transfer>
    </template>
    <div style="margin-top: 20px">
      <template>
        <el-button type="primary"
                   @click="timeSave">开放预约时间</el-button>
      </template>
<!--      复用时按星期复用（没有提交修改则自动复用），我再考虑一下怎么写这个星期-日期换算-->
      <template>
        <el-button type="primary" disabled
                   @click="open2">沿用上周时间</el-button>
      </template>

<!--      <template>
      <el-popover
          title="已选时间格式"
          placement="right"
          trigger="click"
          width="auto">
        <div style="font-size: 15px" v-for="(x) in this.value">{{timeShifting(x)}}</div>
        <el-button slot="reference" type="primary">查看</el-button>
      </el-popover>
      </template>-->
<!--<el-divider></el-divider>
&lt;!&ndash;      <div v-for="x in this.value" :key="x">{{timeShifting(x).appointDay}}</div>&ndash;&gt;
&lt;!&ndash;      <el-button v-for="x in this.value" :key="x" @click="this.timeData.push(timeShifting(x))">000</el-button>
      <div>{{timeData}}</div>&ndash;&gt;
      <el-button type="success" @click="timeSave;timeSubmit"></el-button>
&lt;!&ndash;      <div>{{timeData}}</div>&ndash;&gt;
      <template>
        <el-table
          :data="timeData">
          <el-table-column prop="appointDay" label="日期"></el-table-column>
          <el-table-column prop="appointTime.time1" label="时间1"></el-table-column>
          <el-table-column prop="appointTime.time2" label="时间2"></el-table-column>
          <el-table-column prop="appointTime.time3" label="时间3"></el-table-column>
        </el-table>
      </template>-->
<!--      <div>{{this.$store.state.doctor_station.docid}}</div>-->


    </div>
  </div>
</template>


<script>
/*import { getUUID } from '@/utils'*/
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
//这里存放数据
    // eslint-disable-next-line no-unused-vars
    let generateData = _ => {
      let tdata = [];
      let timeShow = [this.getToDay(2) + "上午", this.getToDay(2) + "下午",this.getToDay(3) + "上午", this.getToDay(3) + "下午",this.getToDay(4) + "上午", this.getToDay(4) + "下午",
        this.getToDay(5) + "上午", this.getToDay(5) + "下午",this.getToDay(6) + "上午", this.getToDay(6) + "下午",this.getToDay(7) + "上午", this.getToDay(7) + "下午",
        this.getToDay(8) + "上午", this.getToDay(8) + "下午",];
      let timeReal = ['1.1', '1.2', '2.1', '2.2', '3.1', '3.2','4.1', '4.2','5.1', '5.2','6.1', '6.2','7.1', '7.2',];
      timeShow.forEach((availableTime, index) => {
        tdata.push({
          label: availableTime,
          key: index,
          timeReal: timeReal[index]
        });
      });
      return tdata;
    };
    return {
      tdata: generateData(),
      value: [],
      /*timeData: [{
        appointDay:'',
        appointTime:[{
          time1:'',
          time2:'',
          time3:'',
        }]
      }],*/
      timeData:[],
    };
  },
//监听属性 类似于data概念
  computed: {},
//监控data中的数据变化
  watch: {},
//方法集合
  methods: {
    open1() {
      this.$notify({
        title: '成功',
        message: '已更新预约开放时间',
        type: 'success'
      });
    },

    open2() {
      this.$notify({
        title: '成功',
        message: '已沿用上周开放时间',
        type: 'success'
      });
    },

    getToDay(n) {
      /*let now = new Date();
      let nowDay = now.getDate();
      let nowMonth = now.getMonth() + 1;
      let nowYear = now.getFullYear();
      if (n === 1) {
        let nowDay1 = nowDay + 1;
        return nowYear + "-" + nowMonth + "-" + nowDay1;
      } else if (n == 2) {
        let nowDay2 = nowDay + 2;
        return nowYear + "-" + nowMonth + "-" + nowDay2;
      } else if (n == 3) {
        let nowDay3 = nowDay + 3;
        return nowYear + "-" + nowMonth + "-" + nowDay3;
      } else if (n == 4) {
        let nowDay4 = nowDay + 4;
        return nowYear + "-" + nowMonth + "-" + nowDay4;
      } else if (n == 5) {
        let nowDay5 = nowDay + 5;
        return nowYear + "-" + nowMonth + "-" + nowDay5;
      } else if (n == 6) {
        let nowDay6 = nowDay + 6;
        return nowYear + "-" + nowMonth + "-" + nowDay6;
      } else {
        let nowDay7 = nowDay + 7;
        return nowYear + "-" + nowMonth + "-" + nowDay7;
      }*/

      let today = new Date()
      let theDay = new Date(today)
      theDay.setDate(today.getDate()+n)
      theDay.setMonth(theDay.getMonth())
      let nowDay = theDay.getDate();
      let nowMonth = theDay.getMonth()+1;
      let nowYear = theDay.getFullYear();
      return nowYear + "-" + nowMonth + "-" + nowDay
    },

    timeShifting(x) {
      /*let now = new Date();
      let nowDay = now.getDate();
      let nowMonth = now.getMonth() + 1;
      let nowYear = now.getFullYear();*/
      if (x === 0) {
        /*let nowDay1 = nowDay + 1;*/
        return {
          /*appointDay: nowYear + "-" + nowMonth + "-" + nowDay1,*/
          appointDay: this.getToDay(2),
          appointTime: {
            time1: "9:00",
            time2: "10:00",
            time3: "11:00",
          },
        }
      } else if (x === 1) {
        return {
          appointDay: this.getToDay(2),
          appointTime: {
            time1: "14:00",
            time2: "15:00",
            time3: "16:00",
          },
        }
      } else if (x === 2) {
        return {
          appointDay: this.getToDay(3),
          appointTime: {
            time1: "9:00",
            time2: "10:00",
            time3: "11:00",
          },
        }
      } else if (x === 3) {
        return {
          appointDay: this.getToDay(3),
          appointTime: {
            time1: "14:00",
            time2: "15:00",
            time3: "16:00",
          },
        }
      } else if (x === 4) {
        return {
          appointDay: this.getToDay(4),
          appointTime: {
            time1: "9:00",
            time2: "10:00",
            time3: "11:00",
          },
        }
      } else if (x === 5) {
        return {
          appointDay: this.getToDay(4),
          appointTime: {
            time1: "14:00",
            time2: "15:00",
            time3: "16:00",
          },
        }
      } else if (x === 6) {
        return {
          appointDay: this.getToDay(5),
          appointTime: {
            time1: "9:00",
            time2: "10:00",
            time3: "11:00",
          },
        }
      } else if (x === 7) {
        return {
          appointDay: this.getToDay(5),
          appointTime: {
            time1: "14:00",
            time2: "15:00",
            time3: "16:00",
          },
        }
      } else if (x === 8) {
        return {
          appointDay: this.getToDay(6),
          appointTime: {
            time1: "9:00",
            time2: "10:00",
            time3: "11:00",
          },
        }
      } else if (x === 9) {
        return {
          appointDay: this.getToDay(6),
          appointTime: {
            time1: "14:00",
            time2: "15:00",
            time3: "16:00",
          },
        }
      } else if (x === 10) {
        return {
          appointDay: this.getToDay(7),
          appointTime: {
            time1: "9:00",
            time2: "10:00",
            time3: "11:00",
          },
        }
      } else if (x === 11) {
        return {
          appointDay: this.getToDay(7),
          appointTime: {
            time1: "14:00",
            time2: "15:00",
            time3: "16:00",
          },
        }
      } else if (x === 12) {
        return {
          appointDay: this.getToDay(8),
          appointTime: {
            time1: "9:00",
            time2: "10:00",
            time3: "11:00",
          },
        }
      } else if (x === 13) {
        return {
          appointDay: this.getToDay(8),
          appointTime: {
            time1: "14:00",
            time2: "15:00",
            time3: "16:00",
          },
        }
      }
    },

    timeSave() {
      for (let item of this.value) {
        this.timeData.push(this.timeShifting(item));
        /*this.timeData=this.timeShifting(item);*/
      }

      var path = '/doctor/canorderlist/saveOrderList'
      console.log(this.timeData)
      this.$http({
        url: this.$http.adornUrl(path),
        method: 'post',
        data: this.$http.adornData({
          docId: this.$store.state.doctor_station.docid,
          canOrderDate: JSON.stringify(this.timeData),
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看上传后返回的东西")
          console.log(data)
          this.$notify({
            title: '成功',
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message.error(data.msg)
        }
      })

    },

    /*timeSubmit() {
      var path = '/doctor/canorderlist/save'
      console.log(this.timeData)
      this.$http({
        url: this.$http.adornUrl(path),
        method: 'post',
        data: this.$http.adornData({
          docId: this.$store.state.doctor_station.docid,
          canOrderDate: JSON.stringify(this.timeData),
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看上传后返回的东西")
          console.log(data)
        } else {
          this.$message.error(data.msg)
        }
      })

    },*/
  },
//生命周期 - 创建完成（可以访问当前this实例）
  created() {
    /*this.getToDay();*/
  },
//生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>

</style>
